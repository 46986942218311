import {
  Balance,
  BalancesApiGetBalanceRequest,
  Config,
  createStarkSigner,
  generateLegacyStarkPrivateKey,
  GetSignableCancelOrderRequest,
  GetSignableTradeRequest,
  GetUsersApiResponse,
  ImmutableX,
  IMXError,
  StarkSigner,
  Token,
  TokenAmount,
  TransfersApiGetTransferRequest,
  UnsignedOrderRequest,
  UnsignedTransferRequest,
  WalletConnection,
} from '@imtbl/core-sdk';
import { showNotification } from '@mantine/notifications';
import { captureException } from '@sentry/nextjs';
import {
  ADAPTER_STATUS,
  SafeEventEmitterProvider,
  UserAuthInfo,
  UserInfo,
} from '@web3auth/base';
import type { Web3Auth } from '@web3auth/modal';
import { BigNumber, providers, utils } from 'ethers';

import {
  ALCHEMY_API_KEY,
  ALCHEMY_NETWORK,
  IMMUTABLE_X_ENVIRONMENT,
  YUMON_ETH_ADDRESS,
} from '../config';
import {
  ERROR_CANNOT_GET_STARK_SIGNER,
  ERROR_CONNECTING_TO_WEB3AUTH,
  ERROR_INVALID_USER_INFO,
  ERROR_OPERATION_CANCELLED,
  ERROR_WALLET_BAD_ADDRESS,
  ERROR_WALLET_NOT_CONNECTED,
} from '../config/messages';
import {
  SignInInputExternalWalletDto,
  SignInInputSocialLoginDto,
  SignUpDto,
} from '../services/auth.service';

type BaseSignInDto = Omit<
  SignInInputSocialLoginDto,
  'appPubKey' | 'publicAddress'
>;

type BaseSignUpDto = Omit<SignUpDto, 'appPubKey' | 'publicAddress'>;

export type BuyResponse = {
  requestId?: string;
  status: string;
  tradeId: number;
};

export type CancelResponse = {
  orderId: number;
  status: string;
};

export type CreateTransferResponse = {
  transferId: string;
};

export type DepositResponse = providers.TransactionResponse;

export type L1Balance = BigNumber;

export type L2Balance = Balance;

export type GetUserResponse = GetUsersApiResponse;

export type SellResponse = {
  orderId: number;
  requestId?: string;
  status: string;
  time: number;
};

export type TransferResponse = {
  receiver: string;
  status: string;
  timestamp: string | null;
  token: Token;
  transactionId: number;
  user: string;
};

const alchemyProvider = new providers.AlchemyProvider(
  ALCHEMY_NETWORK,
  ALCHEMY_API_KEY
);

const client = new ImmutableX(
  IMMUTABLE_X_ENVIRONMENT === 'production' ? Config.PRODUCTION : Config.SANDBOX
);

async function getAppPubKeyED25519Key(
  provider: SafeEventEmitterProvider,
  method = 'solanaPrivateKey'
) {
  const { getED25519Key } = await import('@toruslabs/openlogin-ed25519');

  const appScopedPrivKey = await provider.request({ method });

  return getED25519Key(
    Buffer.from((appScopedPrivKey as string).padStart(64, '0'), 'hex')
  ).pk.toString('hex');
}

async function getAppPubKeySECP256K1(
  web3authProvider: SafeEventEmitterProvider,
  method: 'eth_private_key' | 'private_key' = 'eth_private_key'
) {
  const { getPublicCompressed } = await import('@toruslabs/eccrypto');

  const appScopedPrivKey = await web3authProvider.request({ method });

  return getPublicCompressed(
    Buffer.from((appScopedPrivKey as string).padStart(64, '0'), 'hex')
  ).toString('hex');
}

export class Wallet {
  private pendingConnects: number;

  private ethersProvider: providers.Web3Provider | undefined;

  private ethSigner: providers.JsonRpcSigner | undefined;

  private publicAddress: string | undefined;

  private starkSigner: StarkSigner | undefined;

  private readonly web3auth: Web3Auth;

  constructor(web3auth: Web3Auth) {
    this.pendingConnects = 0;
    this.web3auth = web3auth;

    if (this.web3auth.provider) {
      this.ethersProvider = new providers.Web3Provider(this.web3auth.provider);
      this.ethSigner = this.ethersProvider.getSigner();
    }
  }

  get connected(): boolean {
    return this.web3auth.connected;
  }

  public async connect(): Promise<SafeEventEmitterProvider | undefined> {
    this.pendingConnects += 1;

    await this.web3auth.connect();

    if (this.pendingConnects > 0) {
      this.pendingConnects = 0;

      if (!this.web3auth.connected || !this.web3auth.provider) {
        throw new Error(ERROR_CONNECTING_TO_WEB3AUTH);
      }

      this.ethersProvider = new providers.Web3Provider(this.web3auth.provider);
      this.ethSigner = this.ethersProvider.getSigner();
      this.publicAddress = await this.ethSigner.getAddress();

      return this.web3auth.provider;
    }

    return undefined;
  }

  public async disconnect() {
    this.pendingConnects = 0;

    if (this.web3auth.status === ADAPTER_STATUS.CONNECTED) {
      await this.web3auth.logout();
    }

    this.ethersProvider = undefined;
    this.ethSigner = undefined;
    this.publicAddress = undefined;
    this.starkSigner = undefined;
  }

  public async getAddress(): Promise<string> {
    if (!this.publicAddress) {
      if (!this.ethSigner) {
        throw new Error(ERROR_WALLET_NOT_CONNECTED);
      }

      this.publicAddress = await this.ethSigner.getAddress();
    }

    return this.publicAddress;
  }

  public async getAppPubKeyED25519Key() {
    if (!this.web3auth.connected || !this.web3auth.provider) {
      throw new Error(ERROR_WALLET_NOT_CONNECTED);
    }

    return getAppPubKeyED25519Key(this.web3auth.provider);
  }

  public async getAppPubKeySECP256K1() {
    if (!this.web3auth.connected || !this.web3auth.provider) {
      throw new Error(ERROR_WALLET_NOT_CONNECTED);
    }

    return getAppPubKeySECP256K1(this.web3auth.provider);
  }

  public async getUserInfo(): Promise<Partial<UserInfo> & UserAuthInfo> {
    const userInfo = await this.web3auth.getUserInfo();
    const userAuthInfo = await this.web3auth.authenticateUser();

    if (!userInfo || !userAuthInfo) {
      throw new Error(ERROR_INVALID_USER_INFO);
    }

    return { ...userInfo, ...userAuthInfo };
  }

  private getEthSigner(): providers.JsonRpcSigner {
    if (!this.ethSigner) {
      throw new Error(ERROR_WALLET_NOT_CONNECTED);
    }

    return this.ethSigner;
  }

  public async getL1PrivateKey(): Promise<string> {
    if (!this.web3auth.provider) {
      throw new Error(ERROR_WALLET_NOT_CONNECTED);
    }

    return this.web3auth.provider.request({
      method: 'eth_private_key',
    }) as unknown as Promise<string>;
  }

  private async getStarkSigner() {
    if (!this.starkSigner) {
      try {
        const ethSigner = this.getEthSigner();
        const privateKey = await generateLegacyStarkPrivateKey(ethSigner);
        this.starkSigner = createStarkSigner(privateKey);
      } catch {
        throw new Error(ERROR_CANNOT_GET_STARK_SIGNER);
      }
    }

    return this.starkSigner;
  }

  public async getWalletConnection(): Promise<WalletConnection> {
    return {
      ethSigner: this.getEthSigner(),
      starkSigner: await this.getStarkSigner(),
    };
  }
}

export async function executeIfConnected<T>(
  wallet: Wallet,
  userWalletAddress: string,
  callback: () => Promise<T>
) {
  if (!wallet.connected) {
    void wallet.connect();

    throw new Error(ERROR_WALLET_NOT_CONNECTED);
  }

  const walletAddress = await wallet.getAddress();

  if (walletAddress.toLowerCase() !== userWalletAddress.toLowerCase()) {
    throw new Error(ERROR_WALLET_BAD_ADDRESS);
  }

  return callback();
}

export function handleWalletError(err: unknown) {
  switch ((err as Error).message) {
    case ERROR_WALLET_BAD_ADDRESS:
      showNotification({ message: ERROR_WALLET_BAD_ADDRESS, color: 'red' });
      break;
    case ERROR_WALLET_NOT_CONNECTED:
      return;
    default:
      captureException(err);
      showNotification({ message: ERROR_OPERATION_CANCELLED, color: 'red' });
  }
}

export async function buy(
  wallet: Wallet,
  orderId: number
): Promise<BuyResponse> {
  const tradeRequest: GetSignableTradeRequest = {
    order_id: orderId,
    user: await wallet.getAddress(),
  };

  const walletConnection = await wallet.getWalletConnection();

  const createTradeResponse = await client.createTrade(
    walletConnection,
    tradeRequest
  );

  return {
    requestId: createTradeResponse.request_id,
    status: createTradeResponse.status,
    tradeId: createTradeResponse.trade_id,
  };
}

export async function cancel(
  wallet: Wallet,
  orderId: number
): Promise<CancelResponse> {
  const tradeRequest: GetSignableCancelOrderRequest = {
    order_id: orderId,
  };

  const walletConnection = await wallet.getWalletConnection();

  const cancelResponse = await client.cancelOrder(
    walletConnection,
    tradeRequest
  );

  return {
    orderId: cancelResponse.order_id,
    status: cancelResponse.status,
  };
}

export async function depositETHFromL1ToL2(
  wallet: Wallet,
  ethAmount: string
): Promise<DepositResponse> {
  const tokenAmount: TokenAmount = {
    amount: utils.parseUnits(ethAmount, 'ether').toString(),
    type: 'ETH',
  };

  const walletConnection = await wallet.getWalletConnection();

  return client.deposit(walletConnection.ethSigner, tokenAmount);
}

export async function getL1Balance(address: string): Promise<L1Balance> {
  return alchemyProvider.getBalance(address);
}

export async function getL2Balance(address: string): Promise<L2Balance> {
  const getBalanceRequest: BalancesApiGetBalanceRequest = {
    owner: address,
    address: 'ETH',
  };

  return client.getBalance(getBalanceRequest);
}

export async function getOrCreateUser(
  wallet: Wallet
): Promise<GetUserResponse> {
  try {
    return await client.getUser(await wallet.getAddress());
  } catch (error) {
    if (error instanceof IMXError) {
      const walletConnection = await wallet.getWalletConnection();

      await client.registerOffchain(walletConnection);

      return client.getUser(await wallet.getAddress());
    }

    throw error;
  }
}

export async function getTransfer(id: string): Promise<TransferResponse> {
  const getTransferRequest: TransfersApiGetTransferRequest = {
    id,
  };

  const transfer = await client.getTransfer(getTransferRequest);

  return {
    receiver: transfer.receiver,
    status: transfer.status,
    timestamp: transfer.timestamp,
    token: transfer.token,
    transactionId: transfer.transaction_id,
    user: transfer.user,
  };
}

export async function sell(
  wallet: Wallet,
  tokenAddress: string,
  tokenId: string,
  ethAmount: string
): Promise<SellResponse> {
  const unsignedOrderRequest: UnsignedOrderRequest = {
    sell: {
      type: 'ERC721',
      tokenId,
      tokenAddress,
    },
    buy: {
      type: 'ETH',
      amount: utils.parseUnits(ethAmount, 'ether').toString(),
    },
  };

  const walletConnection = await wallet.getWalletConnection();

  const createOrderResponse = await client.createOrder(
    walletConnection,
    unsignedOrderRequest
  );

  return {
    orderId: createOrderResponse.order_id,
    requestId: createOrderResponse.request_id,
    status: createOrderResponse.status,
    time: createOrderResponse.time,
  };
}

export async function setVerificationProperty(
  payload: BaseSignInDto | BaseSignUpDto,
  verifier: string | undefined,
  wallet: Wallet
): Promise<
  SignInInputExternalWalletDto | SignInInputSocialLoginDto | SignUpDto
> {
  switch (verifier) {
    case undefined:
      return {
        ...payload,
        address: await wallet.getAddress(),
      };
    case 'torus':
      return {
        ...payload,
        // TODO: can we use the address instead and recompute the public key in the backend?
        appPubKey: await wallet.getAppPubKeySECP256K1(),
      };
    default:
      throw new Error(`Unknown verifier ${verifier}`);
  }
}

export async function transferETHToYumon(
  wallet: Wallet,
  ethAmount: string
): Promise<CreateTransferResponse> {
  const unsignedTransferRequest: UnsignedTransferRequest = {
    receiver: YUMON_ETH_ADDRESS,
    type: 'ETH',
    amount: utils.parseUnits(ethAmount, 'ether').toString(),
  };

  const walletConnection = await wallet.getWalletConnection();

  const createTransferResponse = await client.transfer(
    walletConnection,
    unsignedTransferRequest
  );

  return {
    transferId: createTransferResponse.transfer_id.toString(),
  };
}

// TODO: find a way to replace fiatToCrypto to top-up wallet
