import type { OPENLOGIN_NETWORK_TYPE } from '@toruslabs/openlogin-utils';

export const API_ORIGIN = process.env.NEXT_PUBLIC_API_ORIGIN as string;
export const CDN_ORIGIN = process.env.NEXT_PUBLIC_CDN_ORIGIN as string;

export const DATA_API_ORIGIN = process.env
  .NEXT_PUBLIC_DATA_API_ORIGIN as string;
export const DATA_APP_ORIGIN = process.env
  .NEXT_PUBLIC_DATA_APP_ORIGIN as string;

export const YUMON_ETH_ADDRESS = process.env
  .NEXT_PUBLIC_YUMON_ETH_ADDRESS as string;

export const IMMUTABLE_X_ENVIRONMENT = process.env
  .NEXT_PUBLIC_IMMUTABLE_X_ENVIRONMENT as string;

export const GOOGLE_TAG_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID as string;
export const GOOGLE_TAG_SERVER_URL = process.env
  .NEXT_PUBLIC_GOOGLE_TAG_SERVER_URL as string;

export const LINK_AUTHORIZATION_DISCORD = process.env
  .NEXT_PUBLIC_LINK_AUTHORIZATION_DISCORD as string;

export const WEB3AUTH_CHAIN_ID = process.env
  .NEXT_PUBLIC_WEB3AUTH_CHAIN_ID as string;
export const WEB3AUTH_CLIENT_ID = process.env
  .NEXT_PUBLIC_WEB3AUTH_CLIENT_ID as string;
export const WEB3AUTH_NETWORK = process.env
  .NEXT_PUBLIC_WEB3AUTH_NETWORK as OPENLOGIN_NETWORK_TYPE;

export const DEFAULT_RETRY_TIMEOUT = Number(
  process.env.NEXT_PUBLIC_DEFAULT_RETRY_TIMEOUT as string
);

export const ALCHEMY_API_KEY = process.env
  .NEXT_PUBLIC_ALCHEMY_API_KEY as string;
export const ALCHEMY_NETWORK = process.env
  .NEXT_PUBLIC_ALCHEMY_NETWORK as string;
