import { ReactNode, useEffect, useState } from 'react';

import {
  createStyles,
  Header,
  Container,
  Button,
  Loader,
  em,
} from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import UserItem from './UserItem';
import { CDN_ORIGIN } from '../../../config';
import useSession from '../../../contexts/SessionContext';
import useWallet from '../../../contexts/WalletContext';

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: 'transparent',
    border: 'none',
  },

  scrolledHeader: {
    backgroundColor: theme.colors.neutral[8],
    border: 'none',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    [`@media (max-width: ${em(280)})`]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },

  nav: {
    flexGrow: 1,
    padding: '2em',

    [`@media (max-width: ${em(280)})`]: {
      display: 'none',
    },
  },

  navlink: {
    textDecoration: 'none',
    color: 'white',
    fontWeight: 700,
  },
}));

export default function AppHeader() {
  const router = useRouter();

  const { classes } = useStyles();

  const { accessToken, user, signIn } = useSession();

  const { wallet } = useWallet();

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    void signIn();
  };

  let avatar: ReactNode;

  if (user) {
    avatar = <UserItem profilePictureUrl={user?.profilePictureUrl ?? ''} />;
  } else if (accessToken === null && wallet) {
    avatar = <Button onClick={handleClick}>Login</Button>;
  } else {
    avatar = <Loader size="sm" />;
  }

  return (
    <Header
      fixed
      height={73}
      className={scroll ? classes.scrolledHeader : classes.header}
      role="banner"
    >
      <Container className={classes.container}>
        <Link href="/">
          <Image
            src={`${CDN_ORIGIN}/studio/logos/yumon_logo_black_white.png`}
            alt="yumon's logo"
            style={{ objectFit: 'contain' }}
            width={100}
            height={50}
          />
        </Link>

        <nav className={classes.nav}>
          {!router.asPath.includes('marketplace') && (
            <Link className={classes.navlink} href="/marketplace">
              Marketplace
            </Link>
          )}
        </nav>

        {router.asPath !== '/auth/signup' && avatar}
      </Container>
    </Header>
  );
}
