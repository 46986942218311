import { ParsedUrlQuery } from 'querystring';

import {
  useState,
  useEffect,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';

import { showNotification } from '@mantine/notifications';
import { captureException, setUser as sentrySetUser } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import useWallet from './WalletContext';
import { DEFAULT_RETRY_TIMEOUT } from '../config';
import {
  ACCESS_TOKEN_KEY,
  DEFAULT_REFRESH_INTERVAL,
  DEFAULT_RETRY_COUNT,
  OAUTH_STATE_KEY,
} from '../config/constants';
import {
  ERROR_DEFAULT,
  ERROR_WALLET_NOT_INITIALIZED,
  SUCCESS_SIGNIN,
  SUCCESS_SIGNOUT,
} from '../config/messages';
import { setVerificationProperty, getOrCreateUser } from '../lib/wallet';
import {
  checkUserAvailability as authCheckUserAvailability,
  signInExternalWallet as authSignInExternalWallet,
  SignInOutputDto,
  signInSocialLogin as authSignInSocialLogin,
  signUp as authSignUp,
  SignUpDto as AuthSignUpDto,
} from '../services/auth.service';
import {
  gtagLogin,
  gtagSetUserData,
  gtagSetUserId,
  gtagSignUp,
} from '../services/gtag.service';
import { me } from '../services/user.service';
import { User } from '../types';

export type SignUpDto = Pick<AuthSignUpDto, 'username'> &
  Pick<Partial<AuthSignUpDto>, 'email'>;

type SessionContextType = {
  accessToken: string | null | undefined;
  user: User | null | undefined;
  signIn: (callback?: string) => Promise<void>;
  signInToYumon: () => Promise<void>;
  signOut: (redirect: boolean) => Promise<void>;
  signUp: (signUpDto: SignUpDto) => Promise<User | undefined>;
};

type SessionProviderProps = {
  children: ReactNode;
};

export const SessionContext = createContext<SessionContextType | undefined>(
  undefined
);

export default function useSession() {
  const context = useContext(SessionContext);

  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }

  return context;
}

export function SessionProvider({ children }: SessionProviderProps) {
  const { push } = useRouter();

  const { wallet } = useWallet();

  const [accessToken, setAccessToken] = useState<string | null | undefined>(
    undefined
  );

  useEffect(() => {
    setAccessToken(localStorage.getItem(ACCESS_TOKEN_KEY) ?? null);
  }, []);

  const signOut = useCallback(
    async (redirect = true) => {
      if (!wallet) {
        throw new Error(ERROR_WALLET_NOT_INITIALIZED);
      }

      await wallet.disconnect();

      setAccessToken(null);

      // TODO: also clear SWR cache here?

      localStorage.removeItem(OAUTH_STATE_KEY);
      localStorage.removeItem(ACCESS_TOKEN_KEY);

      showNotification({ message: SUCCESS_SIGNOUT });

      if (redirect) {
        await push('/');
      }
    },
    [push, wallet]
  );

  const { data: user } = useSWR(accessToken, me, {
    errorRetryCount: DEFAULT_RETRY_COUNT,
    refreshInterval: DEFAULT_REFRESH_INTERVAL,
    shouldRetryOnError: true,
    onErrorRetry(err, key, config, revalidate, { retryCount }) {
      if (retryCount >= DEFAULT_RETRY_COUNT) {
        void signOut(false);
      }
      setTimeout(() => {
        void revalidate({ retryCount });
      }, DEFAULT_RETRY_TIMEOUT);
    },
  });

  useEffect(() => {
    if (user?.username !== undefined) {
      sentrySetUser({
        username: user?.username,
        email: user?.email,
        walletAddress: user?.wallet?.address,
      });
      gtagSetUserData({ emailAddress: user?.email });
      gtagSetUserId(user?.paginationId);
    } else {
      sentrySetUser(null);
      gtagSetUserData({ emailAddress: null });
      gtagSetUserId(null);
    }
  }, [user?.username, user?.email, user?.wallet?.address, user?.paginationId]);

  const signInToYumon = useCallback(async () => {
    if (!wallet) {
      throw new Error(ERROR_WALLET_NOT_INITIALIZED);
    }

    const userInfo = await wallet.getUserInfo();

    let signInOutput: SignInOutputDto | undefined;

    if (userInfo.verifier) {
      signInOutput = await authSignInSocialLogin(userInfo.idToken, {
        appPubKey: await wallet.getAppPubKeySECP256K1(),
      });
    } else {
      signInOutput = await authSignInExternalWallet(userInfo.idToken, {
        address: await wallet.getAddress(),
      });
    }

    setAccessToken(signInOutput.accessToken);

    localStorage.setItem(ACCESS_TOKEN_KEY, signInOutput.accessToken);

    gtagLogin({ method: userInfo.verifier });

    showNotification({ message: SUCCESS_SIGNIN });
  }, [wallet]);

  const signIn = useCallback(
    async (callback?: string) => {
      try {
        if (!wallet) {
          throw new Error(ERROR_WALLET_NOT_INITIALIZED);
        }

        await wallet.connect();

        const userExists = await authCheckUserAvailability({
          address: await wallet.getAddress(),
        });

        if (userExists) {
          await signInToYumon();

          if (callback) {
            await push(callback);
          }
        } else {
          // TODO: check redirection
          const { email } = await wallet.getUserInfo();

          const query: ParsedUrlQuery = {};

          if (callback) {
            query.callback = callback;
          }
          if (email) {
            query.email = email;
          }

          await push({ pathname: '/auth/signup', query });
        }
      } catch (err) {
        captureException(err);
        showNotification({ message: ERROR_DEFAULT, color: 'red' });

        await signOut(false);
      }
    },
    [push, signInToYumon, signOut, wallet]
  );

  const signUp = useCallback(
    async ({
      email: userEmail,
      username,
    }: SignUpDto): Promise<User | undefined> => {
      try {
        if (!wallet) {
          throw new Error(ERROR_WALLET_NOT_INITIALIZED);
        }

        const userInfo = await wallet.getUserInfo();

        await getOrCreateUser(wallet);

        const signUpDto = await setVerificationProperty(
          {
            address: await wallet.getAddress(),
            email: userEmail ?? userInfo.email,
            idToken: userInfo.idToken,
            username,
          },
          userInfo.verifier,
          wallet
        );

        if ('email' in signUpDto && signUpDto.email === '') {
          delete signUpDto.email;
        }

        const newUser = await authSignUp(signUpDto as AuthSignUpDto);

        gtagSetUserData({ emailAddress: newUser.email });
        gtagSignUp({ method: userInfo.verifier });

        return newUser;
      } catch (err) {
        captureException(err);
        showNotification({ message: ERROR_DEFAULT, color: 'red' });
      }

      return undefined;
    },
    [wallet]
  );

  const value = useMemo(
    () => ({
      accessToken,
      user,
      signIn,
      signInToYumon,
      signOut,
      signUp,
    }),
    [accessToken, user, signIn, signInToYumon, signOut, signUp]
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}
