import { MantineThemeOverride } from '@mantine/core';
import { Montserrat } from 'next/font/google';

import { AvatarRarity } from '../types';

const montserrat = Montserrat({ subsets: ['latin'] });

export const BADGE_COLORS_PER_RARITY: Record<AvatarRarity, string> = {
  common: 'gray.8',
  limited: 'teal.8',
  epic: 'primary',
};

const YUMON_THEME: MantineThemeOverride = {
  fontFamily: montserrat.style.fontFamily,
  headings: {
    fontFamily: montserrat.style.fontFamily,
    sizes: {
      h1: {
        fontWeight: 900,
      },
      h2: {
        fontWeight: 800,
      },
      h3: {
        fontWeight: 700,
      },
    },
  },
  colorScheme: 'dark',
  primaryColor: 'primary',
  primaryShade: 4,
  colors: {
    primary: [
      '#DDC8FF',
      '#C8A6FF',
      '#A56DFF',
      '#8840FF',
      '#6619E4',
      '#4B1D9E',
      '#302159',
      '#1B1038',
      '#0C041E',
      '#060016',
    ],
    secondary: [
      '#E8FEFF',
      '#C8FCFF',
      '#8AF8FF',
      '#00F0FF',
      '#00CBD8',
      '#0292A1',
      '#037182',
      '#045163',
      '#053144',
      '#05182D',
    ],
    neutral: [
      '#F1ECFF',
      '#E0D8F8',
      '#B8AED6',
      '#988DB8',
      '#786D97',
      '#564C73',
      '#42395B',
      '#2D2544',
      '#000000',
      '#1A1230',
    ],
  },
  defaultRadius: 'md',
  components: {
    Container: {
      defaultProps: {
        size: 'lg',
      },
    },
    Title: {
      defaultProps: {
        c: 'white',
      },
    },
    Text: {
      defaultProps: {
        c: 'primary.0',
      },
    },
    Input: {
      defaultProps: {
        size: 'xl',
      },
    },
    Anchor: {
      defaultProps: {
        c: 'primary',
        underline: false,
      },
    },
    Badge: {
      defaultProps: {
        px: 5,
      },
    },
  },
};

export default YUMON_THEME;
