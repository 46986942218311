import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from '@web3auth/base';

import '@web3auth/ethereum-provider';

import {
  CDN_ORIGIN,
  WEB3AUTH_CHAIN_ID,
  WEB3AUTH_CLIENT_ID,
  WEB3AUTH_NETWORK,
} from '../config';
import { ERROR_WALLET_NOT_INITIALIZED } from '../config/messages';
import * as walletUtils from '../lib/wallet';

type WalletContextType = {
  buy: (
    userWalletAddress: string,
    orderId: string
  ) => Promise<walletUtils.BuyResponse>;
  cancel: (
    userWalletAddress: string,
    orderId: string
  ) => Promise<walletUtils.CancelResponse>;
  connect: () => Promise<void>;
  depositETHFromL1ToL2: (
    userWalletAddress: string,
    amount: string
  ) => Promise<walletUtils.DepositResponse>;
  disconnect: () => Promise<void>;
  getL1PrivateKey: (userWalletAddress: string) => Promise<string>;
  sell: (
    userWalletAddress: string,
    tokenAddress: string,
    tokenId: string,
    amount: string
  ) => Promise<walletUtils.SellResponse>;
  transferETHToYumon: (
    userWalletAddress: string,
    amount: string
  ) => Promise<walletUtils.CreateTransferResponse>;
  wallet: walletUtils.Wallet | undefined;
};

type WalletProviderProps = {
  children: ReactNode;
};

export const WalletContext = createContext<WalletContextType | undefined>(
  undefined
);

export default function useWallet() {
  const context = useContext(WalletContext);

  if (context === undefined) {
    throw new Error('useWallet must be used within a WalletProvider');
  }

  return context;
}

export function WalletProvider({ children }: WalletProviderProps) {
  const [wallet, setWallet] = useState<walletUtils.Wallet | undefined>();

  useEffect(() => {
    const init = async () => {
      const { Web3Auth } = await import('@web3auth/modal');

      const web3auth = new Web3Auth({
        clientId: WEB3AUTH_CLIENT_ID,
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: WEB3AUTH_CHAIN_ID,
        },
        web3AuthNetwork: WEB3AUTH_NETWORK,
        enableLogging: process.env.NODE_ENV !== 'production',
        sessionTime: 86400 * 7,
        uiConfig: {
          appName: 'Yumon',
          appLogo: `${CDN_ORIGIN}/studio/logos/yumon-diamond.jpg`,
          loginMethodsOrder: ['google', 'facebook', 'twitter', 'apple'],
          theme: 'dark',
        },
      });

      const { OpenloginAdapter } = await import('@web3auth/openlogin-adapter');

      const openloginAdapter = new OpenloginAdapter({
        adapterSettings: {
          uxMode: 'popup',
          whiteLabel: {
            name: 'Yumon',
            logoDark: `${CDN_ORIGIN}/studio/logos/yumon-diamond.jpg`,
            logoLight: `${CDN_ORIGIN}/studio/logos/yumon-diamond.jpg`,
            dark: true,
          },
        },
        loginSettings: {
          mfaLevel: 'none',
        },
      });
      web3auth.configureAdapter(openloginAdapter);

      await web3auth.initModal({
        modalConfig: {
          [WALLET_ADAPTERS.TORUS_EVM]: {
            label: 'torus_evm',
            showOnModal: false,
          },
          [WALLET_ADAPTERS.METAMASK]: {
            label: 'metamask',
            showOnModal: true,
          },
          [WALLET_ADAPTERS.WALLET_CONNECT_V1]: {
            label: 'wallet_connect_v1',
            showOnModal: false,
          },
          [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
            label: 'wallet_connect_v2',
            showOnModal: false,
          },
          [WALLET_ADAPTERS.OPENLOGIN]: {
            label: 'openlogin',
            loginMethods: {
              apple: {
                name: 'apple',
                showOnModal: true,
              },
              discord: {
                name: 'discord',
                showOnModal: false,
              },
              email_passwordless: {
                name: 'email_passwordless',
                showOnModal: false,
              },
              facebook: {
                name: 'facebook',
                showOnModal: true,
              },
              github: {
                name: 'github',
                showOnModal: false,
              },
              google: {
                name: 'google',
                showOnModal: true,
              },
              kakao: {
                name: 'kakao',
                showOnModal: false,
              },
              line: {
                name: 'line',
                showOnModal: false,
              },
              linkedin: {
                name: 'linkedin',
                showOnModal: false,
              },
              reddit: {
                name: 'reddit',
                showOnModal: false,
              },
              sms_passwordless: {
                name: 'sms_passwordless',
                showOnModal: false,
              },
              twitch: {
                name: 'twitch',
                showOnModal: false,
              },
              twitter: {
                name: 'twitter',
                showOnModal: true,
              },
              wechat: {
                name: 'wechat',
                showOnModal: false,
              },
              weibo: {
                name: 'weibo',
                showOnModal: false,
              },
            },
          },
        },
      });

      setWallet(new walletUtils.Wallet(web3auth));
    };

    void init();
  }, []);

  const buy = useCallback(
    async (
      userWalletAddress: string,
      orderId: string
    ): Promise<walletUtils.BuyResponse> => {
      if (!wallet) {
        throw new Error(ERROR_WALLET_NOT_INITIALIZED);
      }

      return walletUtils.executeIfConnected(wallet, userWalletAddress, () =>
        walletUtils.buy(wallet, Number(orderId))
      );
    },
    [wallet]
  );

  const cancel = useCallback(
    async (
      userWalletAddress: string,
      orderId: string
    ): Promise<walletUtils.CancelResponse> => {
      if (!wallet) {
        throw new Error(ERROR_WALLET_NOT_INITIALIZED);
      }

      return walletUtils.executeIfConnected(wallet, userWalletAddress, () =>
        walletUtils.cancel(wallet, Number(orderId))
      );
    },
    [wallet]
  );

  const connect = useCallback(async (): Promise<void> => {
    if (!wallet) {
      throw new Error(ERROR_WALLET_NOT_INITIALIZED);
    }

    await wallet.connect();
  }, [wallet]);

  const depositETHFromL1ToL2 = useCallback(
    async (
      userWalletAddress: string,
      ethAmount: string
    ): Promise<walletUtils.DepositResponse> => {
      if (!wallet) {
        throw new Error(ERROR_WALLET_NOT_INITIALIZED);
      }

      return walletUtils.executeIfConnected(wallet, userWalletAddress, () =>
        walletUtils.depositETHFromL1ToL2(wallet, ethAmount)
      );
    },
    [wallet]
  );

  const disconnect = useCallback(async (): Promise<void> => {
    if (!wallet) {
      throw new Error(ERROR_WALLET_NOT_INITIALIZED);
    }

    await wallet.disconnect();
  }, [wallet]);

  const getL1PrivateKey = useCallback(
    async (userWalletAddress: string): Promise<string> => {
      if (!wallet) {
        throw new Error(ERROR_WALLET_NOT_INITIALIZED);
      }

      return walletUtils.executeIfConnected(wallet, userWalletAddress, () =>
        wallet.getL1PrivateKey()
      );
    },
    [wallet]
  );

  const sell = useCallback(
    async (
      userWalletAddress: string,
      tokenAddress: string,
      tokenId: string,
      amount: string
    ): Promise<walletUtils.SellResponse> => {
      if (!wallet) {
        throw new Error(ERROR_WALLET_NOT_INITIALIZED);
      }

      return walletUtils.executeIfConnected(wallet, userWalletAddress, () =>
        walletUtils.sell(wallet, tokenAddress, tokenId, amount)
      );
    },
    [wallet]
  );

  const transferETHToYumon = useCallback(
    async (
      userWalletAddress: string,
      amount: string
    ): Promise<walletUtils.CreateTransferResponse> => {
      if (!wallet) {
        throw new Error(ERROR_WALLET_NOT_INITIALIZED);
      }

      return walletUtils.executeIfConnected(wallet, userWalletAddress, () =>
        walletUtils.transferETHToYumon(wallet, amount)
      );
    },
    [wallet]
  );

  const value = useMemo(
    () => ({
      buy,
      cancel,
      connect,
      depositETHFromL1ToL2,
      disconnect,
      getL1PrivateKey,
      sell,
      transferETHToYumon,
      wallet,
    }),
    [
      buy,
      cancel,
      connect,
      depositETHFromL1ToL2,
      disconnect,
      getL1PrivateKey,
      sell,
      transferETHToYumon,
      wallet,
    ]
  );

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
}
