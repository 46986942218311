import { useEffect } from 'react';

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { AppProps } from 'next/app';
import { Montserrat } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { mantineCache } from '../.mantine-cache';
import MainLayout from '../components/layouts/main-layout';
import { GOOGLE_TAG_ID, GOOGLE_TAG_SERVER_URL } from '../config';
import YUMON_THEME from '../config/theme';
import { SessionProvider } from '../contexts/SessionContext';
import { WalletProvider } from '../contexts/WalletContext';
import { gtagPageView } from '../services/gtag.service';
import GlobalStyles from '../styles/GlobalStyles';

const montserrat = Montserrat({ subsets: ['latin'] });

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        gtagPageView({
          pagePath: window.location.pathname,
          pageTitle: document.title,
        }),
      500
    );

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setTimeout(
        () => gtagPageView({ pagePath: url, pageTitle: document.title }),
        500
      );
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            '${GOOGLE_TAG_SERVER_URL}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GOOGLE_TAG_ID}');
          `,
        }}
      />
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        emotionCache={mantineCache}
        theme={YUMON_THEME}
      >
          <WalletProvider>
            <SessionProvider>
              <div className={montserrat.className}>
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                  />
                </Head>
                <GlobalStyles />
                <Notifications />
                <MainLayout>
                  <Component {...pageProps} />
                </MainLayout>
              </div>
            </SessionProvider>
          </WalletProvider>
      </MantineProvider>
    </>
  );
}
