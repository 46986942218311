export const ERROR_ADDRESS_CANNOT_BE_EMPTY = 'Address canot be empty';
export const ERROR_CANNOT_GET_STARK_SIGNER = "Can't get Stark signer";
export const ERROR_CONNECTING_TO_WEB3AUTH =
  'Error while connecting to Web3Auth';
export const ERROR_DEFAULT = 'Something went wrong, please try again.';
export const ERROR_INVALID_USER_INFO = 'Invalid user info';
export const ERROR_OPERATION_CANCELLED = 'Operation cancelled';
export const ERROR_WALLET_BAD_ADDRESS =
  'Wrong wallet, please sign out and sign in again';
export const ERROR_WALLET_NOT_CONNECTED = 'Wallet not connected';
export const ERROR_WALLET_NOT_INITIALIZED = 'Wallet not initialized';

export const SUCCESS_SIGNIN = 'Successful sign in';
export const SUCCESS_SIGNOUT = 'Successful sign out';

export const SUCCESS_DEPOSIT_FROM_L1 =
  'Deposit in progress it might take few minutes to be completed';
export const SUCCESS_PURCHASE_FUNDS_ON_L2 = 'Purchase completed';

export const SUCCESS_LINK_DISCORD_ACCOUNT = 'Account successfully linked';
export const ERROR_LINK_DISCORD_ACCOUNT =
  'An error has occurred while linking your account, please try again later';

export const SUCCESS_PURCHASE = 'Purchase success';
export const INFO_PURCHASE_CANCELED = 'Purchase canceled';
export const ERROR_PURCHASE_FAILED = 'Purchase failed';
export const ERROR_PURCHASE_CANCELED = 'Purchase cancelation failed';
export const ERROR_PURCHASE_CONFIRMATION_FAILED =
  'Purchase confirmation failed';

export const ERROR_TRANSFER_FUNDS_FAILED = 'Transfer to Immutable X failed';

export const ERROR_INSUFFICIENT_FUNDS =
  'Insufficient funds. In order to proceed buy order, please top-up your wallet';

export const ERROR_EMPTY_ORDER = 'Order is empty';

export const ERROR_CONTACT_SUPPORT =
  'Something went wrong, please contact support';
export const ERROR_L1_PRIVATE_KEY_NOT_AVAILABLE =
  'You need to use your wallet to export your private key';
