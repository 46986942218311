import { Menu, Avatar, createStyles, Text, Group } from '@mantine/core';
import Link from 'next/link';

import useSession from '../../../contexts/SessionContext';

type UserItemProps = {
  profilePictureUrl: string;
};

const useStyles = createStyles((theme) => ({
  target: {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  dropdown: {
    backgroundColor: `${theme.colors.primary[7]}`,
    border: 'none',
    fontWeight: 600,
  },

  divider: {
    borderColor: theme.colors.primary[6],
  },

  button: {
    fontWeight: 600,
  },
}));

export default function UserItem({ profilePictureUrl }: UserItemProps) {
  const { classes } = useStyles();

  const { signOut } = useSession();

  const handleClick = () => {
    void signOut(true);
  };

  return (
    <Menu shadow="md" width={120} position="top-end">
      <Menu.Target>
        <Group className={classes.target} spacing={0}>
          <Avatar src={profilePictureUrl} h={40} w={40} />
        </Group>
      </Menu.Target>

      <Menu.Dropdown color="white" w={40} className={classes.dropdown}>
        <Menu.Item component={Link} href="/user/profile">
          <Text c="white">Profile</Text>
        </Menu.Item>
        <Menu.Item component={Link} href="/user/settings">
          <Text c="white">Settings</Text>
        </Menu.Item>
        <Menu.Divider className={classes.divider} />
        <Menu.Item onClick={handleClick} className={classes.button}>
          <Text c="red.6">Sign out</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
