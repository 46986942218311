import {
  createStyles,
  Group,
  Footer,
  Flex,
  Container,
  Stack,
  Text,
} from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';
import {
  FaTwitter,
  FaDiscord,
  FaYoutube,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';

import { CDN_ORIGIN } from '../../../config';
import {
  YUMON_DISCORD_WEB_LINK,
  YUMON_GAMEBOOK_LINK,
  YUMON_GAMES_LINK,
  YUMON_JOBS_LINK,
  YUMON_LANDING_PAGE_URL,
  YUMON_PRIVACY_POLICY_LINK,
  YUMON_TERMS_AND_CONDITIONS_LINK,
} from '../../../config/links';

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 120,
    backgroundColor: theme.colors.neutral[8],
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: `${theme.spacing.md}px ${theme.spacing.md}px`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  links: {
    color: 'white',
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
    },

    a: {
      color: 'white',
      textDecoration: 'none',
    },
  },
}));

export default function AppFooter() {
  const { classes } = useStyles();

  return (
    <Footer
      mt={0}
      py={100}
      height="fit-content"
      className={classes.footer}
      role="contentinfo"
    >
      <Container className={classes.container}>
        <Flex
          justify="space-between"
          direction={{ base: 'column', sm: 'row' }}
          align="flex-start"
          w="100%"
        >
          <Stack w={200}>
            <Link href={YUMON_LANDING_PAGE_URL}>
              <Image
                src={`${CDN_ORIGIN}/studio/logos/yumon_logo_black_white.png`}
                alt="yumon's logo"
                style={{ objectFit: 'contain' }}
                width={100}
                height={50}
              />
            </Link>
            <Text fz="sm" c="white">
              Play unleashed ⚡️
            </Text>
            <Text fz="sm" c="neutral.4">
              Yumon is a global platform that brings people together through
              play. Enter our playground today!
            </Text>
          </Stack>

          <Stack className={classes.links}>
            <a href={YUMON_JOBS_LINK} target="_blank" rel="noreferrer">
              Jobs
            </a>
            <a href="mailto:hello@yumon.app">Contact</a>
            <a href={YUMON_GAMEBOOK_LINK} target="_blank" rel="noreferrer">
              FAQ
            </a>
          </Stack>

          <Stack className={classes.links}>
            <a
              href={YUMON_TERMS_AND_CONDITIONS_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
            <a
              href={YUMON_PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a href={YUMON_GAMES_LINK} target="_blank" rel="noreferrer">
              👽💎⚡️
            </a>
          </Stack>

          <Group spacing="xs" position="right" noWrap>
            <a href={YUMON_DISCORD_WEB_LINK} target="_blank" rel="noreferrer">
              <FaDiscord color="white" size={24} title="Discord" />
            </a>
            <a
              href="https://twitter.com/YUMONworld"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter color="white" size={24} title="Twitter" />
            </a>
            <a
              href="https://www.youtube.com/yumon"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube color="white" size={24} title="YouTube" />
            </a>
            <a
              href="https://www.instagram.com/yumonworld"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram color="white" size={24} title="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/yumon"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin color="white" size={24} title="LinkedIn" />
            </a>
          </Group>
        </Flex>
      </Container>
    </Footer>
  );
}
