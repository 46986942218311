import { API_ORIGIN } from '../config';
import { User } from '../types';

export async function me(bearer: string): Promise<User> {
  const req = await fetch(`${API_ORIGIN}/auth/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
    },
  });

  if (!req.ok) throw new Error('Invalid authorization request');

  return (await req.json()) as User;
}

export async function sendCode(bearer: string, code: string, username: string) {
  const req = await fetch(`${API_ORIGIN}/users/${username}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
    },
    body: JSON.stringify({
      oauthCode: code,
    }),
  });

  if (!req.ok) throw new Error('Invalid authorization request');

  return req.json();
}

export async function flagL1PrivateKeyAsRevealed(
  bearer: string,
  username: string
) {
  const req = await fetch(`${API_ORIGIN}/users/${username}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
    },
    body: JSON.stringify({
      l1PrivateKeyRevealed: true,
    }),
  });

  if (!req.ok) throw new Error('Invalid authorization request');

  return req.json();
}
