import { ReactNode } from 'react';

import { Flex } from '@mantine/core';

import Footer from './Footer';
import Header from './Header';

type MainLayoutProps = {
  children: ReactNode;
};

export default function MainLayout({ children }: MainLayoutProps) {
  return (
    <>
      <Header />
      <Flex>{children}</Flex>
      <Footer />
    </>
  );
}
