export const ACCESS_TOKEN_KEY = 'accessToken';
export const OAUTH_STATE_KEY = 'oauth-state';

export const DEFAULT_REFRESH_INTERVAL = 30000;
export const DEFAULT_RETRY_COUNT = 2;

export const DEFAULT_DECIMAL = 2;

export const DISPLAYED_DECIMAL_ETH = 6;
export const DISPLAYED_DECIMAL_USDC = 2;

export const MARKET_ORDER_OPTIMISTIC_ID = 'id';

export const MARKET_SEARCH_LIMIT = 12;

export const MINT_DELAY_IN_MS = 120000;

export const PRICE_FEES = 0.08;
export const PRICE_BUMP = 1 + PRICE_FEES;

export const SELL_DECIMAL_ETH = 8;
export const FULL_DECIMAL_ETH = 18;

export const CONVERSION_FACTOR_USDC = 1000000000000;
