export const YUMON_LINKS_URL = 'https://links.yumon.world';

export const YUMON_LANDING_PAGE_URL = 'https://www.yumon.world';

export const YUMON_TERMS_AND_CONDITIONS_LINK = `${YUMON_LINKS_URL}/tcs`;
export const YUMON_PRIVACY_POLICY_LINK = `${YUMON_LINKS_URL}/pp`;
export const YUMON_APPLE_APPSTORE_LINK = `${YUMON_LINKS_URL}/apple-appstore`;
export const YUMON_GOOGLE_PLAYSTORE_LINK = `${YUMON_LINKS_URL}/google-playstore`;
export const YUMON_JOBS_LINK = `${YUMON_LINKS_URL}/jobs`;
export const YUMON_GAMEBOOK_LINK = `${YUMON_LINKS_URL}/gamebook`;
export const YUMON_DISCORD_WEB_LINK = `${YUMON_LINKS_URL}/discord-web`;
export const YUMON_GAMES_LINK = `${YUMON_LINKS_URL}/games`;
