export type GtagCurrency = 'USD';

export type GtagItemCategory = 'gems';

export type GtagTransactionMethod = 'apple' | 'google' | 'immutable' | 'stripe';

export type GtagAddToCart = {
  currency: GtagCurrency;
  items: GtagItem[];
  value: number;
};

export type GtagBeginCheckout = {
  currency: GtagCurrency;
  items: GtagItem[];
  value: number;
};

export type GtagEvent = {
  action: string;
  category: string;
  label: string;
  value: string;
};

export type GtagItem = {
  currency: GtagCurrency;
  itemCategory: GtagItemCategory;
  itemId: string;
  itemName: string;
  price: number;
  quantity: number;
};

export type GtagLogin = {
  method?: string;
};

export type GtagPageView = {
  pagePath: string;
  pageTitle: string;
};

export type GtagPostScore = {
  level: string;
  score: number;
};

export type GtagPurchase = {
  currency: GtagCurrency;
  items: GtagItem[];
  transactionId: string;
  value: number;
};

export type GtagSignUp = {
  method?: string;
};

export type GtagUserData = {
  emailAddress: string | null | undefined;
};

export type GtagViewItem = {
  currency: GtagCurrency;
  items: GtagItem[];
  value: number;
};

export type GtagVirtualPageView = {
  pageLocation?: string;
  pagePath: string;
  pageTitle: string;
};

function gtagItemToItem(item: GtagItem) {
  return {
    currency: item.currency,
    item_category: item.itemCategory,
    item_id: item.itemId,
    item_name: item.itemName,
    price: item.price,
    quantity: item.quantity,
  };
}

export function gtagAddToCart({ currency, items, value }: GtagAddToCart) {
  window?.dataLayer?.push({
    event: 'add_to_cart',
    currency,
    items: items.map(gtagItemToItem),
    value,
  });
}

export function gtagBeginCheckout({
  currency,
  items,
  value,
}: GtagBeginCheckout) {
  window?.dataLayer?.push({
    event: 'begin_checkout',
    currency,
    items: items.map(gtagItemToItem),
    value,
  });
}

export function gtagEvent({ action, category, label, value }: GtagEvent) {
  window?.dataLayer?.push({
    event: action,
    event_category: category,
    event_label: label,
    value,
  });
}

export function gtagLogin({ method }: GtagLogin) {
  window?.dataLayer?.push({
    event: 'login',
    method,
  });
}

export function gtagPageView({ pagePath, pageTitle }: GtagPageView) {
  window?.dataLayer?.push({
    event: 'page_view',
    page_path: pagePath,
    page_title: pageTitle,
  });
}

export function gtagPostScore({ level, score }: GtagPostScore) {
  window?.dataLayer?.push({
    event: 'post_score',
    level,
    score,
  });
}

export function gtagPurchase({
  currency,
  items,
  transactionId,
  value,
}: GtagPurchase) {
  window?.dataLayer?.push({
    event: 'purchase',
    currency,
    items: items.map(gtagItemToItem),
    transaction_id: transactionId,
    value,
  });
}

export function gtagSetUserData({ emailAddress }: GtagUserData) {
  window?.dataLayer?.push({
    user_data: {
      email_address: emailAddress,
    },
  });
}

export function gtagSetUserId(userId: string | null) {
  window?.dataLayer?.push({
    user_id: userId,
  });
}

export function gtagSignUp({ method }: GtagSignUp) {
  window?.dataLayer?.push({
    event: 'sign_up',
    method,
  });
}

export function gtagTutorialBegin() {
  window?.dataLayer?.push({
    event: 'tutorial_begin',
  });
}

export function gtagTutorialComplete() {
  window?.dataLayer?.push({
    event: 'tutorial_complete',
  });
}

export function gtagViewItem({ currency, items, value }: GtagViewItem) {
  window?.dataLayer?.push({
    event: 'view_item',
    currency,
    value,
    items: items.map(gtagItemToItem),
  });
}

export function gtagVirtualPageView({
  pageLocation,
  pagePath,
  pageTitle,
}: GtagVirtualPageView) {
  window?.dataLayer?.push({
    event: 'virtual_page_view',
    page_location: pageLocation,
    page_path: pagePath,
    page_title: pageTitle,
  });
}
