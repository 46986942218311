import { API_ORIGIN } from '../config';
import { User } from '../types';
import { components } from '../types/yumon-back';

type ExistDto = components['schemas']['ExistDto'];

type ExistReturnDto = components['schemas']['ExistReturnDto'];

export type SignInInputExternalWalletDto =
  components['schemas']['SignInInputExternalWalletDto'];

export type SignInInputSocialLoginDto =
  components['schemas']['SignInInputSocialLoginDto'];

export type SignInOutputDto = components['schemas']['SignInOutputDto'];

export type SignUpDto = components['schemas']['SignUpDto'];

export const checkUserAvailability = async (
  existDto: ExistDto
): Promise<boolean> => {
  const req = await fetch(`${API_ORIGIN}/users/exist`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(existDto),
  });

  if (!req.ok) throw new Error('User exists failed');

  const json = (await req.json()) as ExistReturnDto;

  return json.exist;
};

export const signUp = async (signUpDto: SignUpDto): Promise<User> => {
  const req = await fetch(`${API_ORIGIN}/auth/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(signUpDto),
  });

  if (!req.ok) throw new Error('Sign up failed');

  return (await req.json()) as User;
};

export const signInExternalWallet = async (
  idToken: string,
  signInDto: SignInInputExternalWalletDto
): Promise<SignInOutputDto> => {
  const req = await fetch(`${API_ORIGIN}/auth/signin-external-wallet`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(signInDto),
  });

  if (!req.ok) throw new Error('Sign in failed');

  return (await req.json()) as SignInOutputDto;
};

export const signInSocialLogin = async (
  idToken: string,
  signInDto: SignInInputSocialLoginDto
): Promise<SignInOutputDto> => {
  const req = await fetch(`${API_ORIGIN}/auth/signin-social-login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(signInDto),
  });

  if (!req.ok) throw new Error('Sign in failed');

  return (await req.json()) as SignInOutputDto;
};
